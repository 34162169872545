@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Poppins:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600;900&display=swap);
body {
	margin: 0;
	font-family: "Poppins", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

#title {
	color: white;
	font-weight: 600;
}
/* width */
::-webkit-scrollbar {
	width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgb(167, 167, 167);
	border-radius: 10px;
}


.main-navbar {
	display: -webkit-flex;
	display: flex;
	min-height: 92vh;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	background: aliceblue;
	
}
#title {
	color: white;
	font-weight: 600;
}

.profile {
	color: white !important;
	background: rgb(255, 35, 108) !important;
	width: 60px !important;
	height: 60px !important;
	font-size: 30px !important;
}

.profile-body {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-flex-direction: column;
	        flex-direction: column;
}

.main {
	min-height: 92vh;
}
table.dataTable tbody tr.even {
    background-color: #e8e8e8;
}
.MuiButton-root{
    min-width: 1px !important;
}
.main {
	min-height: 92vh;
}

  table.dataTable tbody tr.even {
    background-color: #e8e8e8;
}
div#expense_table_wrapper{
  margin: 5px;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-md-2 {
    margin-bottom: -1em;
}
.main {
	min-height: 92vh;
	width: 99%;
}
.modal + .modal-backdrop + .modal + .modal-backdrop {display:none}
.main {
	min-height: 92vh;
}

  .ToggleClassBtnRadio {
	width: 150px;
  }
  input[type="radio"] {
    display: none;
}

.main {
	min-height: 92vh;
}

.main {
	min-height: 92vh;
}
body{
    overflow-x:hidden;
}
.hideScrollbar::-webkit-scrollbar{
    display: none; 
   }
.main {
	min-height: 92vh;
}
body {
	overflow-x: hidden; /* Hide horizontal scrollbar */
  }
.main {
	min-height: 92vh;
}
.modal-open .modal { outline: none; }
.modal-content {
	box-shadow: none;
}
.container-fluid.border.m-0.p-1 {
	background-color: aliceblue;
}
.small-box.bg-warning .inner {
    color: white;
}
.small-box.bg-warning a {
    color: white !important;
}
.col-lg-3 .small-box h3, .col-md-3 .small-box h3, .col-xl-3 .small-box h3 {
    font-size: 1.2rem;
}
.content-wrapper{
    margin-left: 0px !important;
}
.main {
  min-height: 92vh;
}

.monthly-calender-root {
  width: -webkit-max-content;
  width: max-content;
  display: grid;
  grid-template-columns: repeat(7, 3rem);
  grid-template-rows: auto repeat(6, 3rem);
  grid-gap: 10px;
}

.monthly-calender-day {
  height: 100%;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.wrapper {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	min-height: 100vh;
	background: #0250c5;
	background: linear-gradient(to left, #0250c5, #d43f8d);
	z-index: 1;
	font-family: "Montserrat", sans-serif;
}

.wrapper::before {
	content: "";
	display: block;
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-image: url(../../static/media/img-01.66fe947f.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.heading {
	color: white;
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif;
	font-weight: 900;
}

.sub-head {
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif;
	font-weight: 500;
}
.login-temp {
	z-index: 1;
}

